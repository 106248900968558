import { Grid, Paper, TextField, Button, Checkbox, Input, InputLabel, FormControl, FormControlLabel, FormLabel, Slide, RadioGroup, Radio, InputProps, InputAdornment, Dialog, DialogTitle, DialogActions, DialogContent } from "@material-ui/core";
import MaskedInput from 'react-text-mask';
import React, {useState} from "react";
import './App.css';
import { slice } from "lodash";

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

function CCInfo(props) {

  const [ttip,setTtip] = useState({open:false,title:'',text:''});

  function handleChange(field,value){
    let newVal=value;
    props.setState(prev=>{
      let ts = {...prev}
      ts[field]=newVal;
      return ts;
    })
  }

  return (
      <Slide direction={props.state.direction} in={true}>
      <div className="app-container">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Paper className="card card-full">
            <h2>Card on File</h2>
            <Grid container spacing={2}>
              <Grid item xs={1} style={{textAlign:'left'}}>
                <FormControlLabel
                      control={
                      <Checkbox
                          checked={props.state.requestCardOnFile}
                          onChange={(e)=>{
                          handleChange('requestCardOnFile',e.target.checked)
                          }}
                          name="checkedCC"
                          color="primary"
                      />
                      }
                  />
              </Grid>
              <Grid item xs={11} style={{textAlign:"left",paddingTop:"19px"}}>
                <span>Keep credit card on file and invoice as requested by me or an existing authorized user. Subject to <a onClick={()=>{
                setTtip(prev=>{
                  let ts={...prev}
                  ts={open:true,title:'Terms & Conditions of Credit Card Authorization',text:`I authorize Ring's End, Inc. and any of its subsidiary companies to charge the credit card indicated in this authorization form according to the terms outlined above.  I certify that I am the primary or joint account holder of this credit card and that I will not dispute the payment with my credit card company, so long as the transaction corresponds to the terms indicated in this form.`}
                  return(ts)
                })
                }} href="#">terms and conditions</a>.</span>
              </Grid>
              {props.state.requestCardOnFile?(
                <React.Fragment>
                  <Grid item xs={12} lg={12}>
                    <TextField style={{width:"100%"}}
                    autocomplete="cc-name"
                    helperText={props.state.formErrors['ccName']?props.state.formErrors['ccName']:''}
                    error={props.state.formErrors['ccName']?true:false}
                    label="Name on Card" required={props.state.requestCardOnFile} variant="outlined" size="small" defaultValue={props.state.ccName} onChange={(e)=>{handleChange('ccName',e.target.value)}}/>
                  </Grid>
                  
                  {/* ------------------- */}
                  <Grid item xs={12}>
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {props.state.ccNum?<img src={props.state.ccIcon} style={{height:'20px'}}/>:''}
                          </InputAdornment>
                        ),
                      }}
                      autocomplete="cc-number"
                      style={{width:"100%"}}
                      helperText={props.state.formErrors['ccNum']?props.state.formErrors['ccNum']:''}
                      error={props.state.formErrors['ccNum']?true:false}
                      label="Credit Card Number" required={props.state.requestCardOnFile} variant="outlined" size="small" defaultValue={props.state.ccNum}
                      onKeyUp={(e)=>{
                        let val = e.target.value;
                        if(!e.key.match(/\d|Backspace|ArrowLeft|ArrowRight/)||val.match(/^3\d{15}/)||val.match(/^\d{17}/)){e.target.value=val.slice(0,val.length-1)}
                      }}
                      onChange={(e)=>{
                        let val = e.target.value;
                        handleChange('ccNum',val);
                        let ccType='generic';
                        if(val.match(/^4/)){ccType='visa'}
                        if(val.match(/^3/)){ccType='amex'}
                        if(val.match(/^5/)){ccType='mastercard'}
                        if(val.match(/^6/)){ccType='discover'}
                        handleChange('ccIcon',`${ccType}.svg`)
                      }}
                    />
                  </Grid>
                  
                  <Grid item xs={4} lg={2}>
                    <TextField style={{width:"100%"}}
                      autoComplete="cc-exp"
                      helperText={props.state.formErrors['ccExpiration']?props.state.formErrors['ccExpiration']:''}
                      error={props.state.formErrors['ccExpiration']?true:false}
                      label="Expires" required={props.state.requestCardOnFile} variant="outlined" size="small" defaultValue={props.state.ccExpiration}
                      onKeyUp={(e)=>{
                        let val = e.target.value;
                        if(!e.key.match(/\d|Backspace/)||val.match(/^\d\d\/\d\d\d\d.$/)){e.target.value=val.slice(0,val.length-1)}
                        if(e.keyCode!==8){
                          if(val.match(/^\d\d$/)){e.target.value=`${val}/`}
                        }
                      }}
                      onChange={(e)=>{
                        handleChange('ccExpiration',e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} lg={2}>
                    <TextField style={{width:"100%"}}
                    autocomplete="cc-csc"
                    helperText={props.state.formErrors['ccCode']?props.state.formErrors['ccCode']:''}
                    error={props.state.formErrors['ccCode']?true:false}
                    label="CVV" required={props.state.requestCardOnFile} variant="outlined" size="small" defaultValue={props.state.ccCode}
                    onKeyUp={(e)=>{
                      let val = e.target.value;
                      if(!e.key.match(/\d|Backspace|ArrowLeft|ArrowRight/)||val.match(/^\d{5}/)){e.target.value=val.slice(0,val.length-1)}
                    }}
                    onChange={(e)=>{handleChange('ccCode',e.target.value)}}
                    />
                  </Grid>
                  <Grid item xs={4} lg={8}>
                    
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField required={props.state.requestCardOnFile} name="cc-address-line1" autocomplete="billing address-line1"
                    helperText={props.state.formErrors['ccAddress']?props.state.formErrors['ccAddress']:''}
                    error={props.state.formErrors['ccAddress']?true:false}
                    style={{width:"100%"}} label="Billing Address" variant="outlined" size="small" value={props.state.ccAddress} defaultValue={props.state.billingAddress} onChange={(e)=>{handleChange('ccAddress',e.target.value)}}/>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField name="cc-address-line2" autocomplete="billing address-line2"
                    helperText={props.state.formErrors['ccAddress2']?props.state.formErrors['ccAddress2']:''}
                    error={props.state.formErrors['ccAddress2']?true:false}
                    style={{width:"100%"}} label="Apt./Unit/Suite" variant="outlined" size="small" value={props.state.ccAddress2} defaultValue={props.state.billingAddress2} onChange={(e)=>{handleChange('ccAddress2',e.target.value)}}/>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField required={props.state.requestCardOnFile} name="cc-city" autocomplete="billing address-level2"
                    helperText={props.state.formErrors['ccCity']?props.state.formErrors['ccCity']:''}
                    error={props.state.formErrors['ccCity']?true:false}
                    style={{width:"100%"}} label="City" variant="outlined" size="small" value={props.state.ccCity} defaultValue={props.state.billingCity} onChange={(e)=>{handleChange('ccCity',e.target.value)}}/>
                  </Grid>
                  <Grid item xs={4} md={2}>
                    <TextField required={props.state.requestCardOnFile} name="cc-state" autocomplete="billing address-level1"
                    helperText={props.state.formErrors['ccState']?props.state.formErrors['ccState']:''}
                    error={props.state.formErrors['ccState']?true:false}
                    style={{width:"100%"}} label="State" variant="outlined" size="small" value={props.state.ccState.toUpperCase()} defaultValue={props.state.billingState}
                    onChange={(e)=>{
                      if(e.target.value&&e.target.value.length>2){e.target.value=e.target.value.replace(/^([A-Z]{2}).*/,"$1")}
                      handleChange('ccState',e.target.value)
                    }}/>
                  </Grid>
                  <Grid item xs={4} lg={2}>
                    <TextField
                      style={{width:"100%"}} name="cc-postal-code" autocomplete="billing postal-code"
                      helperText={props.state.formErrors['ccZip']?props.state.formErrors['ccZip']:''}
                      error={props.state.formErrors['ccZip']?true:false}
                      label="Billing Zip" required={props.state.requestCardOnFile} variant="outlined" size="small" value={props.state.ccZip} defaultValue={props.state.billingZip} 
                      onKeyUp={(e)=>{
                        let val = e.target.value;
                        if(!e.key.match(/\d|Backspace|ArrowLeft|ArrowRight/)||val.match(/^\d{6}/)){e.target.value=val.slice(0,val.length-1)}
                      }}
                      onChange={(e)=>{
                        handleChange('ccZip',e.target.value);
                      }}
                    />
                  </Grid>
                </React.Fragment>
              ):''}
            </Grid>
            {/* <pre>{JSON.stringify(props.state,null,2)}</pre> */}
            </Paper>
          </Grid>
      </Grid>
      <Dialog fullWidth open={ttip.open}>
        <DialogTitle>{ttip.title}</DialogTitle>
        <DialogContent>{ttip.text}</DialogContent>
        <DialogActions><Button onClick={()=>{setTtip(prev=>{
          let ts={...prev}
          ts={open:false,title:'',text:''}
          return(ts)
        })}}>Close</Button></DialogActions>
      </Dialog>
      </div>
      </Slide>
  );
}

export default CCInfo;
