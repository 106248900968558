import React,{ useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Grid, Paper, Typography, TextField, Button ,Slide, List, ListItem, makeStyles,
         ListItemText, FormControlLabel, Checkbox, Tooltip, Select, MenuItem, FormControl, InputLabel,
         Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText } from "@material-ui/core";
import {Autocomplete} from '@material-ui/lab';
import {LocationOn as LocationOnIcon, KeyboardArrowRight, KeyboardArrowDown, Info} from "@material-ui/icons";
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';
import './App.css';
import Geocode from "react-geocode";

const autocompleteService = { current: null };
const cache = {};

const containerStyle = {
  width: '100%',
  height: '400px'
};

const libraries=["places"];

const markers = [
  {id:30,lat:42.3484401, lng:-71.0875714, name: 'Boston', yard:true},
  {id:31,lat:42.3748463, lng:-71.2101029, name: 'Waltham', yard:true},
  {id:32,lat:42.4973272, lng:-71.1242157, name: 'Woburn', yard:true},
  {id:33,lat:43.6828470, lng:-70.2905930, name: 'Portland', yard:true},
  {id:34,lat:43.6369190, lng:-70.2528170, name: 'South Portland', yard:true},
  {id:35,lat:43.7691100, lng:-70.2025700, name: 'Yarmouth'},
  {id:36,lat:43.5013610, lng:-70.4471150, name: 'Saco', yard:true},
  {id:37,lat:43.0938535, lng:-70.7939951, name: 'Portsmouth', yard:true},
  {id:38,lat:42.8160800, lng:-70.8803020, name: 'Newburyport'},
  {id:39,lat:42.9530609, lng:-70.8328682, name: 'Hampton'},
  {id:40,lat:42.3155014, lng:-71.2099528, name: 'Newton'},
  {id:40,lat:41.90544, lng:-70.92044, name: 'Middleboro'},
  {id:40,lat:41.69284, lng:-70.93521, name: 'New Bedford'},
]

const useStyles = makeStyles({
  floatingLabelFocusStyle: {
    color: "white"
  }
});

function InStoreAccount(props) {

  const [value, setVValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const classes = useStyles();

  const handleListItemClick = (event, index) => {
    handleChange('selectedStoreIndex',index);
    handleChange('mainStore',props.state.nearestLocs[index].name);
    handleChange('mainStoreDetails',props.state.nearestLocs[index]);
  };

  // const onLoad = React.useCallback(function callback(map) {
  //   const bounds = new window.google.maps.LatLngBounds();
  //   map.fitBounds(bounds);
  //   map.setCenter(props.state.mapCenter);
  //   //handleChange('map',map);
  // }, [])

  // const onUnmount = React.useCallback(function callback(map) {
  //   handleChange('map',null);
  // }, [])

  async function setValue(newVal){
      setVValue(newVal);
      if(newVal&&newVal.place_id){
        doGeoFromAddress(newVal.description)
      }
  }

  function geocodeAddress(address){
    
    return new Promise(async (resolve,reject)=>{
      if(cache[address]){
        console.log(`Resolving ${address} from cache`)
        resolve(cache[address])
      }else{
        try{
          //Geocode.setApiKey(process.env.GEOCODE_KEY);
          Geocode.setApiKey('AIzaSyDLTFMt5zvI6Gg9_xNNa-zbAEuPoAzpPnI');
          let response = await Geocode.fromAddress(address);
          cache[address]=response;
          resolve(response);
        }catch(error){
          console.log(`ERROR!:${error}`)
        }
      }
    })
  }

  function doGeoFromAddress(address){
    geocodeAddress(address).then(
      (response) => {
        let address = {
          street_number:'',
          street:'',
          city:'',
          state:'',
          zip:''
        };
        for (let i = 0; i < response.results[0].address_components.length; i++) {
          for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
            switch (response.results[0].address_components[i].types[j]) {
              case "street_number":
                address.street_number = response.results[0].address_components[i].long_name;
                break;
              case "route":
                  address.street = response.results[0].address_components[i].long_name;
                  break;
              case "locality":
                address.city = response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                address.state = response.results[0].address_components[i].long_name;
                break;
              case "postal_code":
                address.zip = response.results[0].address_components[i].long_name;
                break;
            }
          }
        }
        if(!address.street_number&&!address.street&&!props.state.ignoreAddressError){handleChange('showAddressError',true)}
        const { lat, lng } = response.results[0].geometry.location;
        let nearest = getNearest({ lat, lng });
        let z = getZoom(props.state.mainStore==='ringsend.com'?nearest[1].dist:nearest[0].dist);
        props.setState(prev=>{
          let ts = {...prev};
          ts.billingGeo={lat,lng};
          ts.mapCenter={lat,lng};
          ts.nearestLocs=nearest;
          ts.mapZoom=z
          return ts;
        })
      },
      (error) => {
        console.error(error);
      }
    ); 
  }

  function getZoom(nearme){
    let z = 10;
    if(nearme<=2){z=13}
    else if(nearme<=6){z=12}
    else if(nearme<=10){z=11}
    else if(nearme<=25){z=10}
    else if(nearme<=50){z=9}
    else if(nearme<=100){z=8}
    else if(nearme<=200){z=7}
    else if(nearme<=400){z=6}
    else if(nearme<=800){z=5.5}
    else if(nearme<=1000){z=4.5}
    else if(nearme<=1200){z=4}
    else{z=3.5}
    return z;
  }


  useEffect(()=>{
    document.addEventListener('onautocomplete', function(e) {
      if(e.target.hasAttribute('autocompleted')){
        if(e.target.dataset.formfield){
          handleChange(e.target.dataset.formfield,e.target.value)
        }
        // console.log(`Autofill detected: ${JSON.stringify(e.target.dataset,null,2)}`)
      }
    })
  },[])

  const fetch = React.useMemo(
      () =>
        throttle((request, callback) => {
          autocompleteService.current.getPlacePredictions(request, callback);
        }, 500),
      [],
  );

    useEffect(() => {
        let active = true;
    
        if (!autocompleteService.current && window.google) {
          autocompleteService.current = new window.google.maps.places.AutocompleteService({
            types: ['(regions)']
        });
        }
        if (!autocompleteService.current) {
          return undefined;
        }
    
        if (inputValue === '') {
          handleChange('addressOptions',value ? [value] : []);
          return undefined;
        }
    
        fetch({ input: inputValue }, (results) => {
          if (active) {
            let newOptions = [];
    
            if (value) {
              newOptions = [value];
            }
    
            if (results) {
              newOptions = [...newOptions, ...results];
            }
    
            handleChange('addressOptions',newOptions);
          }
        });
    
        return () => {
          active = false;
        };
    }, [value, inputValue, fetch]);

  function handleChange(field,value){
    props.setState(prev=>{
      let ts = {...prev}
      if(field==='billingState'){value=value.toUpperCase()}
      ts[field]=value;
      return ts;
    });
  }

  function getNearest(loc){
    let nearest = [];
    let storeOptions = [...markers];
    if(props.state.businessType==='Building Construction'){
      storeOptions = storeOptions.filter(s=>s.yard)
    }
    storeOptions.forEach(m=>{
      let dist = getDistanceFromLatLonInKm(loc.lat,loc.lng,m.lat,m.lng)
      nearest.push({id:m.id,name:m.name,dist})
    })
    nearest.sort((a, b) => (a.dist > b.dist) ? 1 : -1);
    if(nearest[0].dist>120.701){ //greater-than 75mi
      //nearest.unshift({id:91,name:'ringsend.com',dist:0})
      handleChange('isLocal',false)
    }else{
      handleChange('isLocal',true)
    }
    handleChange('mainStore',nearest[0].name);
    handleChange('mainStoreDetails',nearest[0]);
    return nearest;
  }

  function errorFree(){
    const fieldErrors = {};
    if(!props.state.billingAddress.trim()){
      fieldErrors['billingAddress']='Please enter your billing address';
    }
    if(!props.state.billingCity.trim()){
      fieldErrors['billingCity']='Please enter your billing city';
    }
    if(!props.state.billingState.trim()){
      fieldErrors['billingState']='Please enter your billing state';
    }
    if(!props.state.billingZip.trim()){
      fieldErrors['billingZip']='Please enter your billing zip code';
    }
    handleChange('formErrors',fieldErrors);
    return Object.keys(fieldErrors).length?false:true;
  }

  function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2-lat1);  // deg2rad below
    var dLon = deg2rad(lon2-lon1); 
    var a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
      Math.sin(dLon/2) * Math.sin(dLon/2)
      ; 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    var d = R * c; // Distance in km
    return d;
  }
  
  function deg2rad(deg) {
    return deg * (Math.PI/180)
  }

  function setStep(s,d){
    props.setState(prev=>{
      let ts = {...prev};
      ts.step=s;
      ts.direction=d;
      return ts;
    })
  }

  return (
      <React.Fragment>
      <Slide direction={props.state.direction} in={true}>
      <div className="app-container">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Paper className="card card-full">
            <h2>{props.state.inStoreAccountDefault?'Enter Billing Address & Select Preferred Store':'Enter Billing Address & Select Preferred Store'}</h2>
            <LoadScript googleMapsApiKey={process.env.REACT_APP_MAP_KEY} libraries={libraries} >
            <Grid container spacing={2}>
                {props.state.inStoreAccountDefault?(
                  
                  <Grid item xs={12} style={{textAlign:'left'}}>
                  <h4>Billing Address</h4>
                  </Grid>
                  
                  
                  
                ):(
                  <React.Fragment>
                  <Grid item xs={6} style={{textAlign:'right',marginBottom:'15px'}}>
                  <Button variant="contained" onClick={()=>{handleChange('inStoreAccount',true)}}> <KeyboardArrowDown/> Yes</Button>
                  </Grid>
                  <Grid item xs={6} style={{textAlign:'left'}}>
                    <Link to="/prefs" onClick={()=>{
                      setStep(props.state.step+1,'left');
                      handleChange('inStoreAccount',false);
                      }} style={{textDecoration:'none'}}>
                      <Button variant="contained">No <KeyboardArrowRight/> </Button>
                    </Link>
                  </Grid>
                  </React.Fragment>
                )}
                
                {props.state.inStoreAccount?( 
                    <React.Fragment>
                    {/* <Grid item xs={12} md={12} style={{marginTop:'-15px'}}>
                        <Autocomplete
                            id="google-map-demo"
                            style={{ width: '100%' }}
                            size="small"
                            getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                            filterOptions={(x) => x}
                            options={props.state.addressOptions}
                            autoComplete
                            includeInputInList
                            filterSelectedOptions
                            value={value}
                            onChange={(event, newValue) => {
                                handleChange('addressOptions',newValue ? [newValue, ...props.state.addressOptions] : props.state.addressOptions);
                                setValue(newValue);
                            }}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                            }}
                            renderInput={(params) => (
                                <TextField required {...params} label="Address Search" variant="outlined" fullWidth />
                            )}
                            renderOption={(option) => {
                                const matches = option.structured_formatting.main_text_matched_substrings;
                                const parts = parse(
                                option.structured_formatting.main_text,
                                matches.map((match) => [match.offset, match.offset + match.length]),
                                );
                                    //console.log(parts);
                                return (
                                <Grid container alignItems="center">
                                    <Grid item>
                                    <LocationOnIcon  />
                                    </Grid>
                                    <Grid item xs>
                                    {parts.map((part, index) => (
                                        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                        {part.text}
                                        </span>
                                    ))}

                                    <Typography variant="body2" color="textSecondary">
                                        {option.structured_formatting.secondary_text}
                                    </Typography>
                                    </Grid>
                                </Grid>
                                );
                            }}
                        />
                    </Grid> */}
                    <Grid item xs={12} md={6}>
                      <TextField inputProps={{"data-formfield":"billingAddress"}} required name="address-line1" autocomplete="billing address-line1"
                      helperText={props.state.formErrors['billingAddress']?props.state.formErrors['billingAddress']:''}
                      error={props.state.formErrors['billingAddress']?true:false}
                      style={{width:"100%"}} label="Street Address" variant="outlined" size="small" value={props.state.billingAddress} defaultValue={props.state.billingAddress}
                      onChange={(e)=>{
                        handleChange('billingAddress',e.target.value);
                        if(!props.state.ccAddress){handleChange('ccAddress',e.target.value)}
                      }}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField inputProps={{"data-formfield":"billingAddress2"}} name="address-line2" autocomplete="billing address-line2"
                      helperText={props.state.formErrors['billingAddress2']?props.state.formErrors['billingAddress2']:''}
                      error={props.state.formErrors['billingAddress2']?true:false}
                      style={{width:"100%"}} label="Apt./Unit/Suite" variant="outlined" size="small" value={props.state.billingAddress2} defaultValue={props.state.billingAddress2}
                      onChange={(e)=>{
                        handleChange('billingAddress2',e.target.value);
                        if(!props.state.ccAddress2){handleChange('ccAddress2',e.target.value)}
                      }}/>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField inputProps={{"data-formfield":"billingCity"}} required name="city" autocomplete="billing address-level2"
                      helperText={props.state.formErrors['billingCity']?props.state.formErrors['billingCity']:''}
                      error={props.state.formErrors['billingCity']?true:false}
                      style={{width:"100%"}} label="City" variant="outlined" size="small" value={props.state.billingCity} defaultValue={props.state.billingCity}
                      onChange={(e)=>{
                        handleChange('billingCity',e.target.value);
                        if(!props.state.ccCity){handleChange('ccCity',e.target.value)}
                      }}/>
                    </Grid>
                    <Grid item xs={4} md={2}>
                      <TextField name="state" autocomplete="billing address-level1"
                        helperText={props.state.formErrors['billingState']?props.state.formErrors['billingState']:''}
                        error={props.state.formErrors['billingState']?true:false}
                        size="small" variant="outlined"
                        label="State" value={props.state.billingState.toUpperCase()} defaultValue={props.state.billingState.toUpperCase()}
                        onChange={(e)=>{
                          if(e.target.value&&e.target.value.length>2){e.target.value=e.target.value.replace(/^([A-Z]{2}).*/,"$1")}
                          handleChange('billingState',e.target.value);
                        }}/>
                    </Grid>
                    <Grid item xs={4} md={2}>
                      <TextField inputProps={{"data-formfield":"billingZip"}} required name="postal-code" autocomplete="billing postal-code"
                      helperText={props.state.formErrors['billingZip']?props.state.formErrors['billingZip']:''}
                      error={props.state.formErrors['billingZip']?true:false}
                      style={{width:"100%"}} label="Zip Code" variant="outlined" size="small" value={props.state.billingZip} defaultValue={props.state.billingZip}
                      onChange={(e)=>{
                        handleChange('billingZip',e.target.value)
                        if(!props.state.ccZip){handleChange('ccZip',e.target.value)}
                      }}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Button style={{width:'100%'}} variant="contained" color="primary"
                      disabled={props.state.billingAddress&&props.state.billingCity&&props.state.billingState&&props.state.billingZip?false:true}
                      onClick={()=>{
                          if(errorFree()){
                              let derivedAddress = `${props.state.billingAddress}, ${props.state.billingCity}, ${props.state.billingState}, ${props.state.billingZip}`;
                              doGeoFromAddress(derivedAddress);
                          }
                        }}>Find Stores</Button>
                    </Grid>
                    <Grid item md={8} className="hide-map">
                      <GoogleMap
                        options={{
                          maxZoom:21
                        }}
                        mapContainerStyle={containerStyle}
                        center={props.state.mapCenter}
                        defaultCenter={{lat: 43.132088,lng: -70.705769}}
                        // defaultCenter={{lat: 41.375206,lng: -73.121848}}
                        zoom={props.state.mapZoom}
                        //onLoad={setPlaceService}
                        // onUnmount={onUnmount}
                      >
                        {props.state.businessType==='Building Construction'?markers.filter(s=>s.yard).map(marker=><Marker key={marker.name} title={marker.name} position={marker}/>):markers.map(marker=><Marker key={marker.name} title={marker.name} position={marker}/>)}
                        {props.state.billingGeo?<Marker animation={2} title="You are here" icon="https://ringsend-public.s3.amazonaws.com/images/content/header-menu-cart/userpin.png" key="myLoc" position={props.state.billingGeo}/>:''}
                      </GoogleMap>
  
                    </Grid>
                    <Grid item md={12} xs={12} sm={12} lg={4} xl={4}>
                    <h4 style={{marginTop:'0',marginBottom:'10px'}}>Pick Your Preferred Store <Tooltip disableFocusListener title="Enter your billing address and select the location nearest to you. Your account is available to use in all stores, no matter which one is your preferred store."><Info color="primary" style={{height:'16px'}}/></Tooltip></h4>
                      <div className="store-list-long">
                        
                      <List dense>
                        {props.state.nearestLocs.length?props.state.nearestLocs.map((loc,idx)=>(
                          <ListItem
                          selected={props.state.selectedStoreIndex === idx}
                          onClick={(event) => handleListItemClick(event, idx)}
                          button key={loc.name}>
                            <ListItemText>{loc.name} <span style={{fontWeight:'100',color:'#666'}}>({(Number(loc.dist)*0.621371).toFixed(1)} mi)</span></ListItemText>
                          </ListItem>
                        )):''}
                      </List>
                      </div>
                      {/* {props.state.mainStore==='ringsend.com'?'':(
                      <React.Fragment>
                      <div style={{marginTop:'10px',paddingBottom:'0px'}}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={props.state.requestCredit}
                              onChange={(e)=>{
                                handleChange('requestCredit',e.target.checked)
                              }}
                              name="checkedCredit"
                              color="primary"
                            />
                          }
                          label={<div style={{fontWeight:800}}>I would like trade credit <Tooltip disableFocusListener title="Credit Accounts give you the ability to buy something now and pay for it later. Bills are due and payable within 15 days after the date of billing and are past due after 30 days. Ring’s End even offers prompt payment discounts."><Info color="primary" style={{height:'16px'}}/></Tooltip></div>}
                        />
                      </div>
                      <div style={{paddingTop:'0'}}><span className="disclaimer">A credit manager will follow-up with you via email.</span></div></React.Fragment>)} */}
                    </Grid>
                    </React.Fragment>
                ):''}
                
            </Grid></LoadScript>
            
            </Paper>
          </Grid>
          
      </Grid>
      
      </div>
      </Slide>
      <Dialog open={props.state.showAddressError} onClose={()=>{handleChange('showAddressError',false)}} aria-labelledby="address-error">
        <DialogTitle id="address-error">Please Check the Address</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please check the address you entered and correct it if needed
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={()=>{handleChange('showAddressError',false)}} color="primary">
            Let Me Fix It
          </Button>
          <Button variant="contained" onClick={()=>{
            handleChange('showAddressError',false);
            handleChange('ignoreAddressError',true);
          }} >
            I'm Sure it's Right
          </Button>
        </DialogActions>
      </Dialog>
      </React.Fragment>
  );
}

export default InStoreAccount;
