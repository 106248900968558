const today = new Date();
const yyyy = today.getFullYear();
let mm = today.getMonth() + 1; // Months start at 0!
let dd = today.getDate();
const shortid = require('shortid');

if (dd < 10) dd = '0' + dd;
if (mm < 10) mm = '0' + mm;
const state = {
    jpc:true,
    showNar:false,
    tempId:shortid.generate(),
    path:'Pro',
    proType:'painter',
    location:{pathname:"/"},
    loc:"/",
    step:0,
    direction:'left',
    firstName:'',
    middleName:'',
    lastName:'',
    email:'',
    emailVerified:false,
    verifyEmailCode:'',
    verifyEmailLater:false,
    verifyEmailSent:false,
    mobile:'',
    verifyMobile:true,
    verifyMobileCode:'',
    showCodeVerify:false,
    showCodeResent:false,
    mobileVerified:false,
    licenseNum:'',
    licenseState:'',
    password:'',
    businessName:'',
    businessPhone:'',
    businessPhoneSameAsMobile:true,
    businessType:'',
    existingAcctNum:'',
    inStoreAccount:true,
    inStoreAccountDefault:true,
    billingAddress:'',
    billingAddress2:'',
    billingCity:'',
    billingState:'',
    billingZip:'',
    billingGeo:null,
    showAddressError:false,
    ignoreAddressError:false,
    mapCenter:{lat: 42.8574185,lng: -70.6979203},
    mapZoom:7.4,
    addressOptions:[],
    nearestLocs:[],
    selectedStoreIndex:0,
    mainStore:'Boston',
    isLocal:true,
    requestOnlineAccount:false,
    requestCredit:false,
    creditLimit:0,
    payByCheck:false,
    requirePO:false,
    taxExempt:false,
    jobAccounts:[],
    authorizedToCharge:[],
    subscribeEducation:false,
    emailInvoices:false,
    subscribeMarketing:false,
    subscribePrintMarketing:false,
    accountCreationAuthorized:false,
    accountCreationSignature:'',
    accountCreationSignatureDate:mm + '/' + dd + '/' + yyyy,
    requestCardOnFile:false,
    ccName:'',
    ccNum:'',
    ccNumRedacted:'',
    ccAddress:'',
    ccAddressRedacted:'',
    ccAddress2:'',
    ccAddress2Redacted:'',
    ccCity:'',
    ccCityRedacted:'',
    ccState:'',
    ccStateRedacted:'',
    ccZip:'',
    ccZipRedacted:'',
    ccIcon:'',
    ccExpiration:'',
    ccExpirationRedacted:'',
    ccCode:'',
    ccCodeRedacted:'',
    steps:[
        // {
        //     label:'Choose Account Type',
        //     pro:false,
        //     url: '/'
        // },
        {
            label:'Contact Info',
            pro:false,
            url: '/'//'/personal-info'
        },
        {
            label:'Account Info',
            pro:false,
            url: '/account-info'
        },
        {
            label:'Address & Store',
            pro:false,
            url: '/in-store-acct'
        },
        {
            label:'Account Options',
            pro:false,
            url: '/account-options'
        },
        {
            label:'Credit Card Info',
            pro:false,
            url: '/cc-info'
        },
        {
            label:'Thank You',
            pro:false,
            url: '/thank-you'
        }
    ],
    map:null,
    placeService:null,
    formErrors:{},
    finished:false,
    showFinishing:false
};
export default state;